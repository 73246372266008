<template>
  <div>
    <div class="min-vh-100 container-box">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            ภาพรวมการขาย
          </h1>
        </b-col>
        <b-col xl="8" class="text-right d-flex justify-content-end">
          <b-button v-b-toggle.sidebar-1 class="btn-filter">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">ค้นหา</span>
          </b-button>
        </b-col>
      </CRow>

      <div class="mt-3 bg-white p-3 p-md-0">
        <div v-if="statusData">
          <div class="dashboard-status-box p-1">
            <div
              class="dashboard-status p-2"
            >
              <p class="m-0 main-label my-auto one-line">
                จำนวนสินค้าที่ขาย (ชิ้น)
              </p>
              <p class="m-0 dashboard-status-value">
                {{ statusData.totalProductSaleUnit | numeral("0,0") }}
              </p>
            </div>
          </div>
          <div class="dashboard-status-box p-1">
            <div
              class="dashboard-status p-2"
            >
              <p class="m-0 main-label my-auto one-line">
                มูลค่าที่ขายได้ (บาท)
              </p>
              <p class="m-0 dashboard-status-value">
                ฿ {{ statusData.totalProductSalePrice | numeral("0,0.00") }}
              </p>
            </div>
          </div>
          <div class="dashboard-status-box p-1">
            <div
              class="dashboard-status p-2"
            >
              <p class="m-0 main-label my-auto one-line">
                จำนวนสมาชิกทั้งหมด (คน)
              </p>
              <p class="m-0 dashboard-status-value">
                {{ statusData.totalUser | numeral("0,0") }}
              </p>
            </div>
          </div>
          <div class="dashboard-status-box p-1">
            <div
              class="dashboard-status p-2"
            >
              <p class="m-0 main-label my-auto one-line">
                จำนวนสมาชิกใหม่ (คน)
              </p>
              <p class="m-0 dashboard-status-value">
                {{ statusData.totalNewUser | numeral("0,0") }}
              </p>
            </div>
          </div>
          <div class="dashboard-status-box p-1">
            <div
              class="dashboard-status p-2"
            >
              <p class="m-0 main-label my-auto one-line">รอการยืนยันชำระเงิน</p>
              <p class="m-0 dashboard-status-value">
                {{ statusData.waitPaymentOrder | numeral("0,0") }}
              </p>
            </div>
          </div>
          <div class="dashboard-status-box p-1">
            <div
              class="dashboard-status p-2"
            >
              <p class="m-0 main-label my-auto one-line">อยู่ระหว่างจัดส่ง</p>
              <p class="m-0 dashboard-status-value">
                {{ statusData.waitDelivery | numeral("0,0") }}
              </p>
            </div>
          </div>
          <div class="dashboard-status-box p-1">
            <div
              class="dashboard-status p-2"
            >
              <p class="m-0 main-label my-auto one-line">คืนสำเร็จ</p>
              <p class="m-0 dashboard-status-value">
                {{ statusData.waitReturnComplete | numeral("0,0") }}
              </p>
            </div>
          </div>
          <div class="dashboard-status-box p-1">
            <div
              class="dashboard-status p-2"
            >
              <p class="m-0 main-label my-auto one-line">คำขอคืน</p>
              <p class="m-0 dashboard-status-value">
                {{ statusData.waitReturn | numeral("0,0") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 bg-white py-3 py-sm-0">
        <h1 class="mr-sm-4 header-main p-3">ยอดขาย (บาท)</h1>
        <b-row no-gutters class="p-3">
          <b-col md="12">
            <LineChart
              :xData="chartDataTotalSale.listSales.label"
              :yData="chartDataTotalSale.listSales.data"
            />
          </b-col>
        </b-row>
      </div>

      <div class="mt-3 bg-white py-3 py-sm-0">
        <h1 class="mr-sm-4 header-main p-3">ยอดขาย (จำนวนผู้ซื้อ)</h1>
        <b-row no-gutters class="p-3">
          <b-col md="12">
            <BarChart
              :xData="chartDataTotalSale.listUnit.label"
              :yData="chartDataTotalSale.listUnit.data"
              label="จำนวนผู้ซื้อ"
            />
          </b-col>
        </b-row>
      </div>

      <div class="mt-3 bg-white py-3 py-sm-0">
        <h1 class="mr-sm-4 header-main p-3">จำนวนผู้สมัครใหม่</h1>
        <b-row no-gutters class="p-3">
          <b-col md="12">
            <LineChart
              :xData="chartData.label"
              :yData="chartData.data"
            />
          </b-col>
        </b-row>
      </div>

      <div class="mt-3 bg-white py-3 py-sm-0">
        <b-row no-gutters>
          <b-col md="6">
            <div class="px-3 pt-3">
              <b-row>
                <b-col md="7">
                  <h1 class="header-main">การจัดอันดับสินค้า</h1>
                </b-col>
                <b-col md="5" class="text-right"
                  ><b-button variant="link" @click="exportFile(0)">
                    นำออกข้อมูล
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-row class="no-gutters overflow-auto">
                    <b-col>
                      <b-button-group class="btn-group-status">
                        <b-button
                          v-for="(item, index) in tabFilter"
                          :key="index"
                          @click="
                            getDataToptenRankProductTab(item.name, item.id)
                          "
                          :class="{
                            menuactive:
                              rankProduct.filter.sortOrderId == item.id,
                          }"
                          >{{ item.name }}</b-button
                        >
                      </b-button-group>
                    </b-col>
                  </b-row>
                  <div class="mt-3 bg-white pb-3">
                    <b-row class="no-gutters">
                      <b-col>
                        <b-table
                          class="table-list"
                          striped
                          responsive
                          hover
                          :items="rankProduct.items"
                          :fields="rankProduct.fields"
                          :busy="rankProduct.isBusy"
                          show-empty
                          empty-text="ไม่พบข้อมูล"
                        >
                          <template v-slot:cell(sortOrder)="data">
                            <span>{{ data.index + 1 }}</span>
                          </template>
                          <template v-slot:cell(productName)="data">
                            <router-link
                              :to="'/product/details/' + data.item.productId"
                              target="_blank"
                              class="text-dark"
                            >
                              {{ data.item.productName }}
                            </router-link>
                          </template>
                          <template v-slot:cell(productImageUrl)="data">
                            <div class="position-relative">
                              <div
                                class="square-box b-contain"
                                v-bind:style="{
                                  'background-image':
                                    'url(' +
                                    data.item.productImageUrl +
                                    ')',
                                }"
                              ></div>
                            </div>
                          </template>
                          <template v-slot:cell(sales)="data">
                            <span>{{
                              data.item.sales | numeral("0,0.00")
                            }}</span>
                          </template>
                          <template v-slot:cell(totalUnit)="data">
                            <span>{{
                              data.item.totalUnit | numeral("0,0")
                            }}</span>
                          </template>
                          <template v-slot:table-busy>
                            <div class="text-center text-black my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong class="ml-2">Loading...</strong>
                            </div>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col md="6">
            <div class="px-3 pt-3">
              <b-row>
                <b-col md="7">
                  <h1 class="header-main">การจัดอันดับหมวดหมู่</h1>
                </b-col>
                <b-col md="5" class="text-right"
                  ><b-button variant="link" @click="exportFile(1)">
                    นำออกข้อมูล
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-row class="no-gutters overflow-auto">
                    <b-col>
                      <b-button-group class="btn-group-status">
                        <b-button
                          v-for="(item, index) in tabFilter"
                          :key="index"
                          @click="
                            getDataToptenRankCategoryTab(item.name, item.id)
                          "
                          :class="{
                            menuactive:
                              rankCategory.filter.sortOrderId == item.id,
                          }"
                          >{{ item.name }}</b-button
                        >
                      </b-button-group>
                    </b-col>
                  </b-row>
                  <div class="mt-3 bg-white pb-3">
                    <b-row class="no-gutters">
                      <b-col>
                        <b-table
                          class="table-list"
                          striped
                          responsive
                          hover
                          :items="rankCategory.items"
                          :fields="rankCategory.fields"
                          :busy="rankCategory.isBusy"
                          show-empty
                          empty-text="ไม่พบข้อมูล"
                        >
                          <template v-slot:cell(sortOrder)="data">
                            <span>{{ data.index + 1 }}</span>
                          </template>
                          <template v-slot:cell(sales)="data">
                            <span>{{
                              data.item.sales | numeral("0,0.00")
                            }}</span>
                          </template>
                          <template v-slot:cell(totalUnit)="data">
                            <span>{{
                              data.item.totalUnit | numeral("0,0")
                            }}</span>
                          </template>
                          <template v-slot:table-busy>
                            <div class="text-center text-black my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong class="ml-2">Loading...</strong>
                            </div>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-sidebar
      id="sidebar-1"
      title="ค้นหาแบบละเอียด"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      ref="filterSidebar"
    >
      <div class="px-3 py-2">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-link px-0"
            @click="onClearFilter()"
          >
            ล้างค่า
          </button>
        </div>

        <label class="label-text">ระยะเวลาของข้อมูล</label>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-rang-date"
            v-model="filter.typeDate"
            :options="dateOptions"
            :aria-describedby="ariaDescribedby"
            name="radio-rang-date-options"
            stacked
          ></b-form-radio-group>
        </b-form-group>

          <label class="label-text">วันที่ชำระเงินเริ่มต้น</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            v-model="filter.startDate"
          ></datetime>

          <label class="label-text">วันที่ชำระเงินสิ้นสุด</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            v-model="filter.endDate"
          ></datetime>

          <div class="text-center">
            <p class="text-danger" v-if="errorDate">
              วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
            </p>
          </div>

        <InputSelect
          class="mb-4"
          title="ประเภทลูกค้า"
          name="roleId"
          :options="optionsRole"
          v-model="filter.roleId"
          @onDataChange="handleChangeRoleId"
          valueField="id"
          textField="name"
        />
        
        <div class="text-center mt-4">
          <button
            type="button"
            class="btn btn-purple button"
            @click="getDataByStatus"
          >
            ค้นหา
          </button>
        </div>
      </div>
    </b-sidebar>
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import LineChart from "./../components/LineChart.vue";
import BarChart from "./../components/BarChart.vue";
import axios from "axios";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import InputSelect from "@/components/inputs/InputSelect";

export default {
  components: {
    LineChart,
    ModalLoading,
    InputSelect,
    BarChart
  },
  data() {
    return {
      statusData: null,
      errorDate: false,
      filter: {
        roleId: 0,
        startDate: null,
        endDate: null,
        typeDate: 0
      },
      chartData: {
        label: [],
        data: [],
      },
      chartDataTotalSale: {
        listSales: {
          label: [],
          data: [],
        },
        listUnit: {
          label: [],
          data: [],
        }
      },
      optionsRole: [
        { id: 0, name: "ทั้งหมด" },
        { id: 4, name: "ลูกค้าทั่วไป" },
        { id: 1, name: "ร้านค้าทั่วไป" },
      ],
      tabFilter: [
        { id: 6, name: "จากยอดขาย" },
        { id: 4, name: "จากจำนวนที่ขายได้" },
      ],
      rankProduct: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          pageNo: 1,
          perPage: 10,
          search: "",
          roleId: 0,
          startDate: null,
          endDate: null,
          sortOrderId: 6,
        },
        fields: [
          {
            key: "sortOrder",
            label: "ลำดับ",
            class: "w-50px",
          },
          {
            key: "productImageUrl",
            label: "",
            class: "w-50px px-0",
          },
          {
            key: "productName",
            label: "รายละเอียด",
            class: "text-left",
          },
          {
            key: "sales",
            label: "ยอดขาย",
            class: "w-100px",
          },
          {
            key: "totalUnit",
            label: "จำนวนที่ขายได้ (ชิ้น)",
            class: "w-100px",
          },
        ],
      },
      rankCategory: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          pageNo: 1,
          perPage: 10,
          search: "",
          roleId: 0,
          startDate: null,
          endDate: null,
          sortOrderId: 6,
        },
        fields: [
          {
            key: "sortOrder",
            label: "ลำดับ",
            class: "w-50px",
          },
          {
            key: "name",
            label: "ชื่อหมวดหมู่",
            class: "text-left",
          },
          {
            key: "sales",
            label: "ยอดขาย",
            class: "w-100px",
          },
          {
            key: "totalUnit",
            label: "จำนวนที่ขายได้ (ชิ้น)",
            class: "w-100px",
          },
        ],
      },
      dateOptions: [
        { text: 'ดูวันนี้', value: 1 },
        { text: 'สัปดาห์นี้', value: 2 },
        { text: 'เดือนก่อนหน้านี้', value: 3 },
        { text: 'เดือนนี้', value: 4 },
        { text: 'ปีนี้', value: 5 },
        { text: 'กำหนดเอง', value: 0 },
      ]
      // chart: {
      //   name: "Basket",
      //   filterValue: "Month",
      //   text: "Line : Units per Basket Average Basket Size (THB excl. VAT)",
      //   textRight: "Number of Order",
      //   filter: ["Day", "Month", "Year", "Export"],
      //   export: "ReportBasket",
      //   label: [],
      //   data: [],
      //   get: () => this.getDashboardTotalSale(),
      //   isFetch: false,
      // },
    };
  },
  created: async function () {
    await this.handleTypeDate(this.filter.typeDate);
    await this.getAll();
    this.$isLoading = true;
  },
  watch: {
    'filter.typeDate'(newValue, oldValue) {
      this.handleTypeDate(newValue);
    }
  },
  methods: {
    handleTypeDate(type){
      let now = new Date();
      let endDate = this.$moment(now).format("YYYY-MM-DD");
      let startDate = this.$moment(now).format("YYYY-MM-DD");
      if(type == 1) {
        startDate = this.$moment(now);
      }else if(type == 2) {
        startDate = this.$moment(now).add(-6, "days");
      }else if(type == 3) {
        startDate = this.$moment(now).add(-1, "months").startOf('month');
        endDate = this.$moment(now).add(-1, "months").endOf('month');
      }else if(type == 4) {
        startDate = this.$moment(now).startOf('month');
      }else if(type == 5) {
        startDate = this.$moment(now).startOf('year');
      }else{
        startDate = this.$moment(now).add(-6, "days");
      }
      this.filter.startDate = this.$moment(startDate).format("YYYY-MM-DD");
      this.filter.endDate = this.$moment(endDate).format("YYYY-MM-DD");
    },
    getAll: async function () {
      await this.getDashboardStatus();
      await this.getDashboardTotalSale();
      await this.getDashboardPerformance();
      await this.getToptenRankProduct();
      await this.getToptenRankCategory();
    },
    getDashboardStatus: async function () {
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Dashboard/GetDashboardOverAllWeb`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.statusData = resData.detail;
      }
    },
    getDashboardPerformance: async function () {
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Dashboard/GetDashboardTotaUnit`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.chartData.label = [];
        this.chartData.data = [];
        resData.detail.forEach((item, index) => {
          this.chartData.label[index] = this.$moment(item.dateTime).format(
            "DD/MM"
          );
          this.chartData.data[index] = item.unit;
        });
      }
    },
    getDashboardTotalSale: async function () {
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Dashboard/GetDashboardTotalSale`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.chartDataTotalSale.listSales.label = [];
        this.chartDataTotalSale.listSales.data = [];
       
        resData.detail.listSales.forEach((item, index) => {
          this.chartDataTotalSale.listSales.label[index] = this.$moment(item.dateTime).format(
            "DD/MM"
          );
          this.chartDataTotalSale.listSales.data[index] = item.sales;
        });

        this.chartDataTotalSale.listUnit.label = [];
        this.chartDataTotalSale.listUnit.data = [];
        resData.detail.listUnit.forEach((item, index) => {
          this.chartDataTotalSale.listUnit.label[index] = this.$moment(item.dateTime).format(
            "DD/MM"
          );
          this.chartDataTotalSale.listUnit.data[index] = item.unit;
        });
      }
    },
    async getToptenRankProduct() {
      let filter = { ...this.rankProduct.filter, ...this.filter };
      this.rankProduct.filter = filter;
      this.$isLoading = false;
      let list = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/dashboard/GetDashboardToptenRankProduct`,
        null,
        this.$headers,
        filter
      );

      if (list.result === 1) {
        this.rankProduct.items = list.detail;
        this.rankProduct.rows = this.rankProduct.items.length;
      }

      this.$isLoading = true;
    },
    async getToptenRankCategory() {
      let filter = { ...this.rankCategory.filter, ...this.filter };
      this.rankCategory.filter = filter;
      this.$isLoading = false;
      let list = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/dashboard/GetDashboardToptenRankCategory`,
        null,
        this.$headers,
        filter
      );

      if (list.result === 1) {
        this.rankCategory.items = list.detail;
        this.rankCategory.rows = this.rankCategory.items.length;
      }

      this.$isLoading = true;
    },
    async getDataByStatus() {
      if (
        this.filter.startDate > this.filter.endDate &&
        this.filter.endDate != ""
      ) {
        this.errorDate = true;
        return;
      }

      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      await this.getAll();
    },
    async onClearFilter() {
      this.filter.typeDate = 1;
      await this.handleTypeDate(this.filter.typeDate);
      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      await this.getAll();
    },
    getDataToptenRankProductTab(status, id) {
      this.rankProduct.filter.sortOrderId = id;
      this.getToptenRankProduct();
    },
    getDataToptenRankCategoryTab(status, id) {
      this.rankCategory.filter.sortOrderId = id;
      this.getToptenRankCategory();
    },
    handleChangeRoleId: async function(value) {
      this.filter.roleId = value;
    },
    exportFile: async function (type) {
      this.$refs.modalLoading.show();

      let path = "";
      let filter = null;
      if (type == 0) {
        path = "dashboard/ExportDashboardTopTenRankProduct";
        filter = this.rankProduct.filter;
      } else if (type == 1) {
        path = "dashboard/ExportDashboardToptenRankCategory";
        filter = this.rankCategory.filter;
      }

      await axios({
        url: `${this.$baseUrl}/api/${path}`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: filter,
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = this.$moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Dashboard-Top-Ten-${
              type === 1 ? "Category" : "Product"
            }-${dateExcel}.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
      this.$refs.modalLoading.hide();
    },
  },
};
</script>

<style scoped>
.dashboard-status-box {
  width: 25%;
  display: inline-block;
}

.dashboard-status {
  border-radius: 5px;
  border: 1px solid #1085ff;
}

.dashboard-status-value {
  color: #1085ff;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: left;
}

.performance-box {
  width: 25% !important;
}

.performance-box div,
.campaign-data-box {
  border: 1px solid #dbdbdb;
}

.image {
  width: 100%;
  padding-top: 20%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* ::v-deep .slick-dots {
    padding-bottom: 10px;
  } */
.menuactive {
  color: #ffb300 !important;
}
@media (max-width: 1200px) {
  .performance-box {
    width: 100% !important;
  }

  .dashboard-status-box {
    width: 50%;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 600px) {
  .f-12-mobile {
    font-size: 12px;
  }
}
</style>
